<template>
  <div v-if="isAdmin()">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>
          <a v-if="anchor" :href="anchor">
            <ciam-icon class="text-gray-400 h-4 w-4 cursor-pointer" name="fa-link"></ciam-icon>
          </a>
          {{ $t('deployment.configuration.upgradeOnDemand.title') }}
        </template>
        <template v-slot:subtitle>{{ $t('deployment.configuration.upgradeOnDemand.subtitle') }}</template>
      </ciam-card-header>
      <ciam-card-content>
        <template v-if="isFree">
          <ciam-alert
            title="Upgrade feature unavailable"
            :description="getUpsellAlertDescription()"
            :type="AlertStatus.WARNING_HREF"
            :href-text="$t('upsell.email.contact')"
            :href="getUpsellAlertHref()"
          ></ciam-alert>
        </template>
        <template v-else>
          <!-- Content section -->
          <div class="space-y-6">
            <!-- Maintenance Status Alert -->
            <maintenance-status-alert
              v-if="maintenanceStatus"
              :status="maintenanceStatus"
              :maintenance-window-time="getMaintenanceWindowTime()"
            />

            <!-- Instance not running message -->
            <div
              v-else-if="deploymentStatus !== 'RUNNING' && currentVersion"
              class="p-4 bg-amber-50 rounded-md border border-amber-200 mt-0"
            >
              <div class="flex items-start gap-3">
                <ciam-icon
                  name="fa-solid fa-exclamation-circle"
                  class="text-amber-500 h-5 w-5 mt-0.5 flex-shrink-0"
                ></ciam-icon>
                <div>
                  <h3 class="font-medium text-amber-700 mb-1">
                    {{ $t('deployment.configuration.upgradeOnDemand.status.instanceNotRunning') }}
                  </h3>
                  <p class="text-sm text-gray-700">
                    {{ $t('deployment.configuration.upgradeOnDemand.status.instanceNotRunningDesc') }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Latest version message when no upgrade is available (third priority) -->
            <div
              v-else-if="!isUpgradeAvailable() && currentVersion && deploymentStatus === 'RUNNING'"
              class="p-4 bg-gray-50 rounded-md mt-0"
            >
              <div class="flex items-center gap-3">
                <ciam-icon name="fa-solid fa-check-circle" class="text-green-500"></ciam-icon>
                <p class="text-gray-700">
                  {{ $t('deployment.configuration.upgradeOnDemand.status.latestVersion') }}
                </p>
              </div>
            </div>

            <!-- Only show version information if an upgrade is available and no maintenance is in progress -->
            <template v-else-if="isUpgradeAvailable() && deploymentStatus === 'RUNNING'">
              <!-- Version information -->
              <div class="space-y-3 p-4 bg-white rounded-md">
                <div class="flex items-center">
                  <div class="text-sm text-gray-600 font-medium w-48">
                    {{ $t('deployment.configuration.upgradeOnDemand.status.currentVersion') }}
                  </div>
                  <div class="bg-gray-100 px-4 py-2 rounded-md">{{ currentVersion || '—' }}</div>
                </div>
                <div class="flex items-center">
                  <div class="text-sm text-gray-600 font-medium w-48">
                    {{ $t('deployment.configuration.upgradeOnDemand.status.latestAvailableVersion') }}
                  </div>
                  <div class="bg-blue-50 px-4 py-2 rounded-md text-blue-800">
                    {{ latestVersion ? `${getLatestMinorVersion()}` : '—' }}
                  </div>
                </div>
              </div>

              <div class="space-y-2 bg-gray-50 p-4 rounded-md">
                <!-- Backup information -->
                <div class="flex items-start gap-3">
                  <ciam-icon name="fa-info-circle" class="text-gray-500 h-5 w-5 mt-0.5 flex-shrink-0"></ciam-icon>
                  <p class="text-sm text-gray-600 italic">
                    {{ $t('deployment.configuration.upgradeOnDemand.information.backup') }}
                  </p>
                </div>
              </div>

              <!-- Version selection and schedule options combined in one card -->
              <div class="border border-gray-200 rounded-md">
                <ciam-card-content-lines>
                  <ciam-card-content-line>
                    <template v-slot:label
                      >{{ $t('deployment.configuration.upgradeOnDemand.dialog.selectVersion') }}
                    </template>
                    <template v-slot:content>
                      <select
                        id="version-select"
                        v-model="selectedVersion"
                        class="w-24 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option v-for="upgrade in upgrades.upgrades" :key="upgrade.name" :value="upgrade.name">
                          {{ upgrade.version }}
                        </option>
                      </select>
                    </template>
                  </ciam-card-content-line>

                  <ciam-card-content-line>
                    <template v-slot:label
                      >{{ $t('deployment.configuration.upgradeOnDemand.dialog.scheduleTitle') }}
                    </template>
                    <template v-slot:content>
                      <div class="flex space-x-6">
                          <input
                            type="radio"
                            id="upgrade-now"
                            name="schedule"
                            :value="MaintenanceTiming.NOW"
                            v-model="selectedStatus"
                            class="h-4 w-4 text-blue-600 focus:ring-blue-500"
                          />
                          <label for="upgrade-now" class="ml-2 text-sm text-gray-700">
                            {{ $t('deployment.configuration.upgradeOnDemand.dialog.asap') }}
                          </label>
                        <div class="flex items-center">
                          <input
                            type="radio"
                            id="upgrade-window"
                            name="schedule"
                            :value="MaintenanceTiming.NEXT_WINDOW"
                            v-model="selectedStatus"
                            class="h-4 w-4 text-blue-600 focus:ring-blue-500"
                          />
                          <label for="upgrade-window" class="ml-2 text-sm text-gray-700">
                            {{ $t('deployment.configuration.upgradeOnDemand.dialog.pending') }}
                          </label>
                        </div>
                      </div>
                    </template>
                  </ciam-card-content-line>

                  <ciam-card-content-line>
                    <template v-slot:content>
                      <div class="flex justify-end">
                        <ciam-button @click="showConsentDialog()" class="primary">
                          {{ $t('deployment.configuration.upgradeOnDemand.nextSteps') }}
                          <template v-slot:right>
                            <ciam-icon name="fa-arrow-right"></ciam-icon>
                          </template>
                        </ciam-button>
                      </div>
                    </template>
                  </ciam-card-content-line>
                </ciam-card-content-lines>
              </div>
            </template>
          </div>
        </template>
      </ciam-card-content>
    </ciam-card>

    <div v-if="!isFree" class="pt-4">
      <!-- Consent Dialog (simplified from previous upgrade dialog) -->
      <ciam-confirm-modal
        :model-value="showDialog"
        @update:model-value="showDialog = $event"
        :confirm-disabled="!allConsentsGiven()"
        confirm-text="Apply"
        cancel-text="Cancel"
        subtitle="Before proceeding, please review and confirm your selections"
        @result="handleDialogResult"
      >
        <template v-slot:header>
          {{ $t('deployment.configuration.upgradeOnDemand.dialog.title') }}
        </template>
        <template v-slot:default>
          <div class="p-4 space-y-6">
            <!-- Upgrade Summary -->
            <div>
              <div class="grid grid-cols-2 gap-4 mb-3">
                <div class="text-gray-600">Current Keycloak version</div>
                <div class="font-medium bg-gray-100 px-3 py-1 rounded col-start-2">
                  {{ extractVersionNumber(currentVersion) }}
                </div>
              </div>
              <div class="grid grid-cols-2 gap-4 mb-3">
                <div class="text-gray-600">Selected upgrade version</div>
                <div class="font-medium bg-yellow-100 px-3 py-1 rounded col-start-2">
                  {{ getSelectedMinorVersion() }}
                  <a
                    v-if="getSelectedReleaseNotesURL()"
                    :href="getSelectedReleaseNotesURL()"
                    target="_blank"
                    class="ml-2 text-blue-600 text-sm hover:underline"
                  >
                    <ciam-icon name="fa-external-link" class="h-3 w-3"></ciam-icon>
                    Release notes
                  </a>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div class="text-gray-600">Scheduled upgrade time</div>
                <div class="font-medium bg-gray-100 px-3 py-1 rounded col-start-2">
                  <div class="flex items-center">
                    <span>{{ selectedStatus === MaintenanceTiming.NOW ? 'Now' : 'Next maintenance window' }}</span>
                    <span class="ml-2 text-xs text-gray-500"> ({{ getScheduledUpgradeTime() }}) </span>
                  </div>
                </div>
              </div>
              <!-- Border separator -->
              <div class="border-t border-gray-200 mt-5 mb-2"></div>
              <!-- Warning message -->
              <div class="flex items-start mt-1">
                <ciam-icon name="fa-lightbulb" class="text-yellow-500 h-5 w-5 mt-0.5 flex-shrink-0"></ciam-icon>
                <p class="ml-2 text-sm text-gray-700">
                  {{ $t('deployment.configuration.upgradeOnDemand.status.needChanges') }}
                  <a href="#" @click.prevent="showDialog = false" class="text-blue-600 underline">{{
                    $t('common.goBack')
                  }}</a>
                  {{ $t('common.toAdjustSettings') }}
                </p>
              </div>
            </div>

            <div class="border-t border-gray-200 mt-3 mb-4"></div>

            <!-- Consents -->
            <div>
              <h3 class="text-sm font-medium text-gray-700 mb-3">
                {{ $t('deployment.configuration.upgradeOnDemand.dialog.consents.title') }}
              </h3>
              <div class="space-y-3">
                <div class="flex items-start p-3 border border-gray-200 rounded-md">
                  <input
                    type="checkbox"
                    id="downtime-consent"
                    v-model="consents.downtime"
                    class="h-4 w-4 mt-0.5 text-blue-600 focus:ring-blue-500"
                  />
                  <label for="downtime-consent" class="ml-3 text-sm text-gray-700">
                    {{ $t('deployment.configuration.upgradeOnDemand.dialog.consents.downtime') }}
                  </label>
                </div>

                <div class="flex items-start p-3 border border-gray-200 rounded-md">
                  <input
                    type="checkbox"
                    id="extensions-consent"
                    v-model="consents.extensions"
                    class="h-4 w-4 mt-0.5 text-blue-600 focus:ring-blue-500"
                  />
                  <label for="extensions-consent" class="ml-3 text-sm text-gray-700">
                    {{ $t('deployment.configuration.upgradeOnDemand.dialog.consents.extensions') }}
                  </label>
                </div>
              </div>
            </div>

            <!-- Support section -->
            <div class="border-t border-gray-200 mt-4 pt-4">
              <div class="flex items-start">
                <ciam-icon name="fa-question-circle" class="text-blue-500 h-5 w-5 mt-0.5 flex-shrink-0"></ciam-icon>
                <p class="ml-2 text-sm text-gray-700">
                  {{ $t('deployment.configuration.upgradeOnDemand.dialog.support.question') }}
                  <router-link 
                    :to="`/support/tickets?organization_id=${$route.query.organization_id}`" 
                    class="text-blue-600 hover:underline"
                  >
                    {{ $t('deployment.configuration.upgradeOnDemand.dialog.support.link') }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:confirm-icon>
          <ciam-icon name="fa-check"></ciam-icon>
        </template>
      </ciam-confirm-modal>
    </div>
  </div>
</template>

<script>
import DeploymentService from '@/pages/deployments/DeploymentService';
import { Notification } from 'vue-notifyjs';
import CiamConfirmModal from '@/components/CiamConfirmModal';
import { MaintenanceTiming } from '@/enums/MaintenanceTiming';
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamCardContent from '@/components/CiamCardContent';
import CiamCardContentLines from '@/components/CiamCardContentLines';
import CiamCardContentLine from '@/components/CiamCardContentLine';
import MaintenanceStatusAlert from './MaintenanceStatusAlert';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import UpsellFeatureService from '@/pages/UpsellFeatureService';
import i18n from '@/i18n';
import PlanService from '@/pages/PlanService';

export default {
  name: 'UpgradeOnDemand',
  components: {
    CiamConfirmModal,
    CiamCard,
    CiamCardHeader,
    CiamCardContent,
    CiamCardContentLines,
    CiamCardContentLine,
    MaintenanceStatusAlert,
    CiamAlert,
  },
  props: {
    deploymentId: {
      type: String,
      required: true,
    },
    deploymentStatus: {
      type: String,
      default: '',
    },
    anchor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFree: null,
      AlertStatus: AlertStatus,
      upgrades: { upgrades: [] },
      showDialog: false,
      selectedStatus: MaintenanceTiming.NOW,
      selectedVersion: null,
      MaintenanceTiming,
      consents: {
        downtime: false,
        extensions: false,
      },
      currentVersion: '',
      latestVersion: '',
      maintenanceWindow: null,
      maintenanceStatus: null, // Will store the current maintenance status
    };
  },
  computed: {},
  methods: {
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(i18n.t(`deployment.configuration.upgradeOnDemand.featureName`));
    },
    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(i18n.t(`deployment.configuration.upgradeOnDemand.featureName`));
    },
    showConsentDialog() {
      this.showDialog = true;
    },
    async handleDialogResult(confirmed) {
      if (confirmed && this.allConsentsGiven()) {
        await this.launchMaintenance();
      } else if (confirmed) {
        Notification.notify({
          message: 'Please agree to all conditions before proceeding.',
          type: 'warning',
        });
      }
      this.showDialog = false;
    },
    async launchMaintenance() {
      try {
        await DeploymentService.upgradeDeployment(this.deploymentId, this.selectedStatus, this.selectedVersion);
        Notification.notify({
          message: this.$t('deployment.configuration.upgradeOnDemand.notifications.success'),
          type: 'info',
        });
        // Refresh maintenance status after scheduling
        await this.checkMaintenanceStatus();
      } catch (err) {
        if (err && err.title === 'upgrade-already-scheduled-exception') {
          Notification.notify({
            message: this.$t('deployment.configuration.upgradeOnDemand.notifications.alreadyScheduled'),
            type: 'warning',
          });
          // Refresh maintenance status to show the scheduled upgrade
          await this.checkMaintenanceStatus();
        } else {
          Notification.notify({
            message: this.$t('deployment.configuration.upgradeOnDemand.notifications.error', {
              error: err.message || err,
            }),
            type: 'danger',
          });
        }
      }
    },
    isUpgradeAvailable() {
      return this.upgrades.upgrades && this.upgrades.upgrades.length > 0;
    },
    allConsentsGiven() {
      return this.consents.downtime && this.consents.extensions;
    },
    extractVersionNumber(versionString) {
      if (!versionString) return '';
      const parts = versionString.split('keycloak-x:');
      return parts.length > 1 ? parts[1] : versionString;
    },
    getSelectedMinorVersion() {
      if (!this.selectedVersion || !this.upgrades.upgrades) return '';
      const selectedUpgrade = this.upgrades.upgrades.find((upgrade) => upgrade.name === this.selectedVersion);
      return selectedUpgrade ? selectedUpgrade.version : '';
    },
    getLatestMinorVersion() {
      if (!this.latestVersion || !this.upgrades.upgrades) return '';
      const selectedUpgrade = this.upgrades.upgrades.find((upgrade) => upgrade.name === this.latestVersion);
      return selectedUpgrade ? selectedUpgrade.version : '';
    },
    getScheduledUpgradeTime() {
      if (this.selectedStatus === MaintenanceTiming.NOW) {
        return this.getImmediateUpgradeTime();
      } else {
        return this.getMaintenanceWindowTime();
      }
    },

    getImmediateUpgradeTime() {
      // Format current time in user's timezone
      const now = new Date();
      return now.toLocaleString();
    },

    getMaintenanceWindowTime() {
      if (this.maintenanceWindow) {
        // Format the maintenance window based on the actual data structure
        const { hours, minutes, utc, duration } = this.maintenanceWindow;

        // Create a formatted time string
        const timeStr = `${hours}:${minutes}`;
        const durationStr = duration ? ` (${duration} hours)` : '';
        const utcStr = utc ? ` ${utc}` : '';

        // Determine if the maintenance window is today or tomorrow
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();

        // Convert maintenance window time to numbers for comparison
        const maintenanceHour = parseInt(hours, 10);
        const maintenanceMinutes = parseInt(minutes, 10);

        // Check if the maintenance window time has already passed for today
        const isToday =
          currentHour < maintenanceHour || (currentHour === maintenanceHour && currentMinutes < maintenanceMinutes);

        const dayText = isToday ? 'Today' : 'Tomorrow';

        return `${dayText} at ${timeStr}${utcStr}${durationStr}`;
      } else {
        return 'Next maintenance window';
      }
    },
    async checkMaintenanceStatus() {
      try {
        const status = await DeploymentService.getMaintenanceStatus(this.deploymentId);

        // If status is not empty and is one of the expected values, set it
        if (status && ['PENDING', 'ASAP', 'RUNNING'].includes(status)) {
          this.maintenanceStatus = status;
        } else {
          this.maintenanceStatus = null;
        }
      } catch (error) {
        console.error('Error checking maintenance status:', error);
        this.maintenanceStatus = null;
      }
    },
    getSelectedReleaseNotesURL() {
      if (!this.selectedVersion || !this.upgrades.upgrades) return null;
      const selectedUpgrade = this.upgrades.upgrades.find((upgrade) => upgrade.name === this.selectedVersion);
      return selectedUpgrade && selectedUpgrade.releaseNoteURL ? selectedUpgrade.releaseNoteURL : null;
    },
    isAdmin() {
      return this.$keycloak.hasRealmRole('ADMIN');
    },
  },
  watch: {
    maintenanceWindow: {
      handler(newVal) {
        console.debug('Maintenance window changed:', newVal);
      },
      immediate: true,
    },
  },
  async created() {
    try {
      // Get deployment details to get current version
      const deployment = await DeploymentService.get(this.deploymentId);

      // Check if the plan is free
      this.isFree = PlanService.isFree(deployment.pricingPlan.name);

      // Only proceed with loading other data if not on a free plan
      if (!this.isFree) {
        const statistics = await DeploymentService.getStatistics(deployment);
        this.currentVersion = statistics.version;

        // Get available upgrades
        this.upgrades = await DeploymentService.listUpgradeAvailable(this.deploymentId);

        // Get maintenance window information
        try {
          this.maintenanceWindow = await DeploymentService.getMaintenanceWindow(this.deploymentId);
        } catch (error) {
          console.error('Error loading maintenance window:', error);
        }

        // Check if there's an ongoing maintenance
        await this.checkMaintenanceStatus();

        if (this.isUpgradeAvailable()) {
          // Use the name field from the last upgrade in the list
          this.selectedVersion = this.upgrades.upgrades[this.upgrades.upgrades.length - 1].name;
          this.latestVersion = this.selectedVersion;
        }
      }
    } catch (error) {
      console.error('Error loading upgrade information:', error);
    }
  },
};
</script>
